// Footer.jsx
import React from 'react';
import { Facebook, Instagram, Twitter, Youtube, Mail, Phone, MapPin, ArrowRight } from 'lucide-react';

const styles = `
  .footer {
    background: linear-gradient(135deg, #1e293b, #0f172a);
    color: #e2e8f0;
    padding: 4rem 1rem 2rem;
    position: relative;
    overflow: hidden;
  }

  .footer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(135deg, #3b82f6, #2563eb);
  }

  .footer-container {
   width:100vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 3rem;
  }

  .footer-section {
    display: flex;
    flex-direction: column;
  }

  .footer-logo {
    font-size: 1.8rem;
    font-weight: bold;
   
    margin-left:2rem;
    background: linear-gradient(135deg, #60a5fa, #3b82f6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .footer-description {
    color: #94a3b8;
    line-height: 1.6;
    margin-bottom: 1.5rem;
    text-align:left;
     margin-left:2rem;
  }

  .footer-section h3 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    color: #f8fafc;
  }

  .footer-links {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .footer-links li {
    margin-bottom: 0.75rem;
  }

  .footer-links a {
    color: #94a3b8;
    text-decoration: none;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
  }

  .footer-links a:hover {
    color: #60a5fa;
    transform: translateX(5px);
  }

  .footer-links a svg {
    margin-right: 0.5rem;
    width: 16px;
    height: 16px;
  }

  .contact-info {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    color: #94a3b8;
  }

  .contact-info svg {
    margin-right: 0.75rem;
    width: 18px;
    height: 18px;
  }

  .social-links {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    margin-left: 2rem;
  }

  .social-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    color: #e2e8f0;
    transition: all 0.3s ease;
  }

  .social-link:hover {
    background: #3b82f6;
    transform: translateY(-3px);
  }

  .newsletter-form {
    display: flex;
    gap: 0.5rem;
    margin-top: 1rem;
  }

  .newsletter-input {
    flex: 1;
    padding: 0.75rem 1rem;
    border: 1px solid #334155;
    border-radius: 0.5rem;
    background: rgba(255, 255, 255, 0.05);
    color: white;
    font-size: 0.9rem;
    transition: all 0.3s ease;
  }

  .newsletter-input:focus {
    outline: none;
    border-color: #3b82f6;
    background: rgba(255, 255, 255, 0.1);
  }

  .newsletter-button {
    padding: 0.75rem 1.5rem;
    background: #3b82f6;
    border: none;
    border-radius: 0.5rem;
    color: white;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .newsletter-button:hover {
    background: #2563eb;
  }

  .footer-bottom {
    margin-top: 4rem;
    padding-top: 2rem;
    border-top: 1px solid #334155;
    text-align: center;
    color: #94a3b8;
    font-size: 0.9rem;
  }

  @media (max-width: 768px) {
    .footer {
      padding: 3rem 1rem 1.5rem;
    }

    .footer-container {
      grid-template-columns: 1fr;
      gap: 2rem;
    }

    .newsletter-form {
      flex-direction: column;
    }

    .newsletter-button {
      width: 100%;
    }
  }
`;

const Footer = () => {
    return (
        <>
            <style>{styles}</style>
            <footer className="footer">
                <div className="footer-container">
                    {/* Company Info Section */}
                    <div className="footer-section">
                        <div className="footer-logo">NOS Esposende</div>
                        <p className="footer-description">
                            telecomunicações de última geração para
                            conectar pessoas e empresas com o que realmente importa.
                        </p>
                        <div className="social-links">
                            <a href="https://www.facebook.com/LOJANOSESPOSENDE" className="social-link"><Facebook size={18} /></a>
                            <a href="https://www.instagram.com/nos_esposende/" className="social-link"><Instagram size={18} /></a>

                        </div>
                    </div>



                    {/* Contact Info Section */}
                    <div className="footer-section">
                        <h3>Contactos</h3>
                        <div className="contact-info">
                            <MapPin />
                            <span>Rua Bombeiro Abilio Nunes Novo nº6A</span>
                        </div>
                        <div className="contact-info">
                            <Phone />
                            <span>+351 253 965 288</span>
                        </div>
                        <div className="contact-info">
                            <Mail />
                            <span>info@nosesposende.com</span>
                        </div>
                    </div>

                    {/* Newsletter Section */}


                </div>

                {/* Footer Bottom */}
                <div className="footer-bottom">
                    <p>© 2024 NOS Esposende. Todos os direitos reservados.</p>
                </div>
            </footer>
        </>
    );
};

export default Footer;