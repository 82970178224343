// FeaturesSection.jsx
import React from 'react';
import { Shield, Clock, Zap, BarChart, Smartphone, Headphones } from 'lucide-react';

const styles = `
  .features-section {
    background: #f8fafc;
    padding: 6rem 1rem;
    position: relative;
    overflow: hidden;
    width:100vw;
    min-height:100vh;
  }

  .features-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 6px;
    background: linear-gradient(135deg, #2563eb, #1e40af);
  }

  .features-container {
   width:
    margin: 0 auto;
  }

  .features-header {
    text-align: center;
    margin-bottom: 5rem;
    position: relative;
  }

  .features-header h2 {
    font-size: 2.5rem;
    color: #1e293b;
    margin-bottom: 1.5rem;
    font-weight: bold;
  }

  .features-header p {
    font-size: 1.25rem;
    color: #64748b;
    max-width: 600px;
    margin: 0 auto;
    line-height: 1.6;
  }

  .features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2.5rem;
    padding: 1rem;
  }

  .feature-card {
    background: white;
    border-radius: 1rem;
    padding: 2rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
  }

  .feature-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .feature-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background: linear-gradient(135deg, #2563eb, #1e40af);
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .feature-card:hover::before {
    opacity: 1;
  }

  .icon-container {
    background: #f0f9ff;
    width: 50px;
    height: 50px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
    color: #2563eb;
    transition: all 0.3s ease;
  }

  .feature-card:hover .icon-container {
    background: #2563eb;
    color: white;
  }

  .feature-card h3 {
    font-size: 1.5rem;
    color: #1e293b;
    margin-bottom: 1rem;
    font-weight: 600;
  }

  .feature-card p {
    color: #64748b;
    line-height: 1.6;
    margin-bottom: 1.5rem;
  }

  .feature-stats {
    display: flex;
    align-items: center;
    color: #2563eb;
    font-weight: 500;
  }

  .feature-stats span {
    margin-right: 0.5rem;
  }

  .testimonial-section {
    margin-top: 5rem;
    text-align: center;
    padding: 2rem;
    background: linear-gradient(135deg, #2563eb11, #1e40af11);
    border-radius: 1rem;
  }

  .testimonial-text {
    font-size: 1.25rem;
    color: #1e293b;
    font-style: italic;
    margin-bottom: 1.5rem;
    max-width: 800px;
    margin: 0 auto 1.5rem;
  }

  .testimonial-author {
    color: #64748b;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    .features-header h2 {
      font-size: 2rem;
    }
    
    .features-grid {
      grid-template-columns: 1fr;
    }
  }
`;

const FeaturesSection = () => {
    const features = [
        {
            icon: Shield,
            title: "Segurança Garantida",
            description: "Proteção avançada para seus dados e conexões, com monitoramento 24/7 ",
            stat: "99.9% de uptime"
        },
        {
            icon: Zap,
            title: "Velocidade Máxima",
            description: "Conexão ultra-rápida com tecnologia de ponta, garantindo streaming suave e downloads instantâneos.",
            stat: "Até 1 Gbps"
        },

        {
            icon: BarChart,
            title: "Performance Garantida",
            description: "Monitoramento constante de desempenho para garantir a melhor experiência possível em todos os serviços.",
            stat: "99% satisfação"
        },

    ];

    return (
        <>
            <style>{styles}</style>
            <section className="features-section">
                <div className="features-container">
                    <div className="features-header">
                        <h2>Por Que Nos Escolher</h2>
                        <p>
                            Combinamos tecnologia de ponta com atendimento excepcional para
                            oferecer a melhor experiência em telecomunicações do mercado.
                        </p>
                    </div>

                    <div className="features-grid">
                        {features.map((feature, index) => (
                            <div key={index} className="feature-card">
                                <div className="icon-container">
                                    <feature.icon size={24} />
                                </div>
                                <h3>{feature.title}</h3>
                                <p>{feature.description}</p>
                                <div className="feature-stats">
                                    <span>★</span>
                                    {feature.stat}
                                </div>
                            </div>
                        ))}
                    </div>


                </div>
            </section>
        </>
    );
};

export default FeaturesSection;