// App.js
import './App.css';
import LogoNOS from './Nos';
import Section2 from './sections/Section2';
import FeaturesSection from './sections/section3';
import GoogleReviews from './sections/section3';
import Footer from './sections/section4';
var mobile = require('is-mobile');
var ismobile = !!mobile()
console.log(ismobile)
function App() {
  return (
    <div className="container">
      <section className="snap-section">
        <LogoNOS />
      </section>

      <section className="snap-section">
        <Section2 />
      </section>
      {!ismobile && <section className="snap-section">
        <FeaturesSection />
      </section>}
      <section className="snap-section1">
        <Footer />
      </section>
    </div>
  );
}

export default App;
