// ServicesSection.jsx
import React from 'react';
import { Wifi, Tv, Play } from 'lucide-react';

const styles = `
  .services-section {
    min-height: 100vh;
    width:100vw;
    background: linear-gradient(135deg, #2563eb, #1e40af);
    padding: 4rem 1rem;
  }

  .container {
   
    margin: 0 auto;
  }

  .header {
    text-align: center;
    margin-bottom: 4rem;
  }

  .header h2 {
    font-size: 3rem;
    font-weight: bold;
    color: white;
    margin-bottom: 1.5rem;
  }

  .header p {
    font-size: 1.25rem;
    color: rgba(255, 255, 255, 0.9);
    max-width: 36rem;
    margin: 0 auto;
  }

  .services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    padding: 1rem;
  }

  .service-card {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 1rem;
    padding: 1.5rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;
  }

  .service-card:hover {
    background: rgba(255, 255, 255, 0.15);
    border-color: rgba(255, 255, 255, 0.2);
    transform: translateY(-5px);
  }

  .icon-wrapper {
    margin-bottom: 1.5rem;
    color: #93c5fd;
  }

  .service-card:hover .icon-wrapper {
    color: #bfdbfe;
  }

  .service-card h3 {
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    margin-bottom: 1rem;
  }

  .service-card p {
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 1.5rem;
    line-height: 1.6;
  }

  .features-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .feature-item {
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 0.75rem;
  }

  .feature-dot {
    width: 6px;
    height: 6px;
    background-color: #93c5fd;
    border-radius: 50%;
    margin-right: 0.5rem;
  }

  .cta-section {
    text-align: center;
    margin-top: 4rem;
  }

  .cta-button {
    background-color: white;
    color: #1e40af;
    padding: 1rem 2rem;
    border-radius: 9999px;
    font-weight: 600;
    font-size: 1.125rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .cta-button:hover {
    background-color: #f0f9ff;
  }

  @media (max-width: 768px) {
    .header h2 {
      font-size: 2.5rem;
    }
    
    .services-grid {
      grid-template-columns: 1fr;
    }
  }
`;

const Section2 = () => {
    const services = [
        {
            icon: Tv,
            title: "TV Premium",
            description: "Mais de 200 canais em HD e 4K com programação exclusiva e conteúdo para toda a família.",
            features: ["Canais HD/4K", "Replay TV"]
        },
        {
            icon: Wifi,
            title: "Internet Fibra",
            description: "Internet de altíssima velocidade com fibra ótica para streaming, jogos e trabalho sem interrupções.",
            features: ["Até 1 Gbps", "Wi-Fi 6", "Baixa Latência"]
        },
        {
            icon: Play,
            title: "Streaming ",
            description: "Acesso às principais plataformas de streaming com qualidade premium e conteúdo exclusivo.",
            features: ["Multi-telas", "4K HDR", "Download Offline"]
        }
    ];

    return (
        <>
            <style>{styles}</style>
            <section className="services-section">
                <div className="container">
                    {/* Header Section */}
                    <div className="header">
                        <h2>Nossos Serviços</h2>
                        <p>
                            Transforme sua experiência digital com nossas soluções integradas de
                            entretenimento e conectividade.
                        </p>
                    </div>

                    {/* Services Grid */}
                    <div className="services-grid">
                        {services.map((service, index) => (
                            <div key={index} className="service-card">
                                <div className="icon-wrapper">
                                    <service.icon size={48} />
                                </div>

                                <h3>{service.title}</h3>
                                <p>{service.description}</p>

                                <ul className="features-list">
                                    {service.features.map((feature, idx) => (
                                        <li key={idx} className="feature-item">
                                            <div className="feature-dot" />
                                            {feature}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>

                    {/* CTA Section */}
                    <div className="cta-section">
                        <button className="cta-button">
                            Conheça Nossos Planos
                        </button>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Section2;