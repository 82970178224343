// LogoNOS.js
import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';

// Function to generate a noise texture
function generateNoiseTexture(width, height) {
    const size = width * height;
    const data = new Uint8Array(3 * size);
    for (let i = 0; i < size; i++) {
        const stride = i * 3;
        const value = Math.floor(Math.random() * 50);
        data[stride] = value;
        data[stride + 1] = value;
        data[stride + 2] = value;
    }
    const texture = new THREE.DataTexture(data, width, height, THREE.RGBFormat);
    texture.needsUpdate = true;
    texture.magFilter = THREE.NearestFilter;
    texture.minFilter = THREE.NearestFilter;
    return texture;
}

const LogoNOS = () => {
    const containerRef = useRef(null);

    useEffect(() => {
        if (!containerRef.current) return;

        // Clear container to avoid duplicate canvases
        containerRef.current.innerHTML = '';

        // ============================================================
        // Scene, Camera, and Renderer
        // ============================================================
        const scene = new THREE.Scene();
        scene.background = new THREE.Color("#111");

        const width = containerRef.current.clientWidth;
        const height = containerRef.current.clientHeight;

        const camera = new THREE.PerspectiveCamera(45, width / height, 0.1, 2000);
        camera.position.set(0, 0, 400);
        camera.lookAt(scene.position);

        const renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setSize(width, height);
        renderer.shadowMap.enabled = true;
        containerRef.current.appendChild(renderer.domElement);

        // ============================================================
        // Post-processing (Bloom)
        // ============================================================
        const composer = new EffectComposer(renderer);
        const renderPass = new RenderPass(scene, camera);
        composer.addPass(renderPass);
        const bloomPass = new UnrealBloomPass(
            new THREE.Vector2(width, height),
            0.3,
            0.4,
            0.85
        );
        composer.addPass(bloomPass);

        // ============================================================
        // Lights
        // ============================================================
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.3);
        scene.add(ambientLight);

        const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
        directionalLight.position.set(0, 50, 50);
        directionalLight.castShadow = true;
        scene.add(directionalLight);

        // ============================================================
        // Background with noise texture
        // ============================================================
        const noiseTexture = generateNoiseTexture(256, 256);
        const bgPlaneGeometry = new THREE.PlaneGeometry(800, 600);
        const bgPlaneMaterial = new THREE.MeshLambertMaterial({
            map: noiseTexture,
            color: "#111"
        });
        const bgPlane = new THREE.Mesh(bgPlaneGeometry, bgPlaneMaterial);
        bgPlane.position.set(0, 0, -50);
        bgPlane.receiveShadow = true;
        scene.add(bgPlane);

        // ============================================================
        // Groups for Circle, Text, and Subtitle
        // ============================================================
        const circleGroup = new THREE.Group();
        scene.add(circleGroup);
        const textGroup = new THREE.Group();
        scene.add(textGroup);
        const subtitleGroup = new THREE.Group();
        scene.add(subtitleGroup);

        // ============================================================
        // Create Circle Segments and Animation
        // ============================================================
        const colorPalette = [0x00ff00, 0xff0000, 0xffc0cb, 0xadd8e6, 0x0000ff, 0xffff00];
        const segments = 30;
        const circleRadius = 100;
        const segWidth = 10;
        const segHeight = 50;
        const segDepth = 20;
        const segAnimDuration = 1.0;
        const segDelay = 0.05;

        let circleResizeStartTime = null;
        const circleResizeDelay = 0.1;
        const circleResizeDuration = 0.3;
        const targetCircleScale = 0.2; // Circle reduces to 20%

        for (let i = 0; i < segments; i++) {
            const geometry = new THREE.BoxGeometry(segWidth, segHeight, segDepth);
            // Shift geometry so that its origin is at the bottom
            geometry.translate(0, segHeight / 2, 0);

            const chosenColor = colorPalette[i % colorPalette.length];
            const material = new THREE.MeshBasicMaterial({ color: chosenColor });
            const segment = new THREE.Mesh(geometry, material);
            segment.scale.set(0, 0, 0);
            segment.castShadow = true;

            const pivot = new THREE.Object3D();
            pivot.add(segment);

            const pointLight = new THREE.PointLight(chosenColor, 2, 500);
            pointLight.castShadow = true;
            pointLight.position.set(0, segHeight, 0);
            pivot.add(pointLight);

            // Distribute pivots evenly in a circle
            const angle = i * (2 * Math.PI / segments);
            pivot.rotation.z = angle;

            segment.userData = {
                delay: i * segDelay,
                duration: segAnimDuration
            };

            circleGroup.add(pivot);
        }

        // ============================================================
        // Load Font for Texts
        // ============================================================
        let fontLoaded = false;
        let loadedFont = null;
        const fontLoader = new FontLoader();
        fontLoader.load(
            'https://threejs.org/examples/fonts/helvetiker_regular.typeface.json',
            (font) => {
                loadedFont = font;
                fontLoaded = true;
                console.log('Font loaded successfully!');
            },
            undefined,
            (err) => {
                console.error('Error loading font:', err);
            }
        );

        // ============================================================
        // Variables for Text Animation Timing
        // ============================================================
        let lettersAdded = false;
        let subtitleAdded = false;

        const lastSegmentTime = ((segments - 1) * segDelay) + segAnimDuration;
        const circleResizeTriggerTime = lastSegmentTime + circleResizeDelay;
        const textTriggerTime = circleResizeTriggerTime + circleResizeDuration;
        const subtitleTriggerTime = textTriggerTime + 0.1;

        const startTime = performance.now();

        // Easing function for smooth animations
        function easeOutBack(t) {
            const c1 = 1.70158;
            const c3 = c1 + 1;
            return 1 + c3 * Math.pow(t - 1, 3) + c1 * Math.pow(t - 1, 2);
        }

        // ============================================================
        // Animation Loop
        // ============================================================
        let animationFrameId;
        function animate() {
            animationFrameId = requestAnimationFrame(animate);
            const currentTime = (performance.now() - startTime) / 1000;

            // Animate circle segments
            circleGroup.children.forEach((pivot) => {
                const seg = pivot.children.find(child => child.isMesh);
                if (seg) {
                    const delay = seg.userData.delay;
                    const duration = seg.userData.duration;
                    if (currentTime > delay) {
                        const t = Math.min((currentTime - delay) / duration, 1);
                        const progress = easeOutBack(t);
                        seg.position.y = progress * circleRadius;
                        seg.scale.set(progress, progress, progress);
                    }
                }
                // Pulsate the point light intensity
                const light = pivot.children.find(child => child.isPointLight);
                if (light) {
                    light.intensity = 2 + 0.5 * Math.sin(currentTime * 2 + pivot.rotation.z);
                }
            });

            // Animate circle group scaling
            if (currentTime > circleResizeTriggerTime) {
                if (circleResizeStartTime === null) {
                    circleResizeStartTime = currentTime;
                }
                const t = Math.min((currentTime - circleResizeStartTime) / circleResizeDuration, 1);
                const easedT = easeOutBack(t);
                const newScale = 1 + (targetCircleScale - 1) * easedT;
                circleGroup.scale.set(newScale, newScale, newScale);
            }

            // Add and animate letters "N" and "S" after circle resize
            if (currentTime > textTriggerTime && !lettersAdded && fontLoaded) {
                lettersAdded = true;
                console.log('Adding letters "N" and "S" at time:', currentTime);

                const sizeN = 40;
                const shapesN = loadedFont.generateShapes("N", sizeN);
                const nGeometry = new THREE.ShapeGeometry(shapesN);
                nGeometry.center();

                const sizeS = 40;
                const shapesS = loadedFont.generateShapes("S", sizeS);
                const sGeometry = new THREE.ShapeGeometry(shapesS);
                sGeometry.center();

                const textMaterial = new THREE.MeshBasicMaterial({ color: "#fff", side: THREE.DoubleSide });
                const nMesh = new THREE.Mesh(nGeometry, textMaterial);
                const sMesh = new THREE.Mesh(sGeometry, textMaterial);
                nMesh.scale.set(0, 0, 0);
                sMesh.scale.set(0, 0, 0);
                nMesh.position.set(-60, 0, 0);
                sMesh.position.set(60, 0, 0);
                nMesh.userData.startTime = currentTime;
                sMesh.userData.startTime = currentTime;

                textGroup.add(nMesh);
                textGroup.add(sMesh);
            }

            // Animate letters "N" and "S"
            textGroup.children.forEach(letter => {
                if (letter.userData.startTime !== undefined) {
                    const t = Math.min((currentTime - letter.userData.startTime) / 1.0, 1);
                    const progress = easeOutBack(t);
                    letter.scale.set(progress, progress, progress);
                }
            });
            textGroup.rotation.y = 0.1 * Math.sin(currentTime * 0.3);

            // Add and animate subtitle "esposende"
            if (currentTime > subtitleTriggerTime && !subtitleAdded && fontLoaded) {
                subtitleAdded = true;
                console.log('Adding subtitle "esposende" at time:', currentTime);

                const sizeSubtitle = 20;
                const shapesSubtitle = loadedFont.generateShapes("esposende", sizeSubtitle);
                const subtitleGeometry = new THREE.ShapeGeometry(shapesSubtitle);
                subtitleGeometry.center();

                const subtitleMaterial = new THREE.MeshBasicMaterial({ color: "#fff", side: THREE.DoubleSide });
                const subtitleMesh = new THREE.Mesh(subtitleGeometry, subtitleMaterial);
                subtitleMesh.scale.set(0, 0, 0);
                subtitleMesh.position.set(0, -50, 0);
                subtitleMesh.userData.startTime = currentTime;
                subtitleGroup.add(subtitleMesh);
            }

            // Animate subtitle
            subtitleGroup.children.forEach(subtitle => {
                if (subtitle.userData.startTime !== undefined) {
                    const t = Math.min((currentTime - subtitle.userData.startTime) / 1.0, 1);
                    const progress = easeOutBack(t);
                    subtitle.scale.set(progress, progress, progress);
                }
            });
            subtitleGroup.rotation.y = 0.1 * Math.cos(currentTime * 0.3);

            composer.render();
        }

        animate();

        // ============================================================
        // Handle Window Resize
        // ============================================================
        const handleResize = () => {
            const width = containerRef.current.clientWidth;
            const height = containerRef.current.clientHeight;
            camera.aspect = width / height;
            camera.updateProjectionMatrix();
            renderer.setSize(width, height);
            composer.setSize(width, height);
        };
        window.addEventListener('resize', handleResize);

        // ============================================================
        // Cleanup on Component Unmount
        // ============================================================
        return () => {
            window.removeEventListener('resize', handleResize);
            cancelAnimationFrame(animationFrameId);
            renderer.dispose();
            if (containerRef.current) {
                containerRef.current.innerHTML = '';
            }
        };
    }, []);

    return <div ref={containerRef} style={{ width: '100vw', height: '100vh' }} />;
};

export default LogoNOS;
